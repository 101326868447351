// src/NotServedPage.js
import React from 'react';
import { Container, Typography, useTheme } from '@mui/material';

const PrivacyAndPolicy = () => {
	const theme = useTheme();

	return (
		<Container
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				minHeight: '100vh',
				backgroundColor: theme.palette.background.default,
				padding: theme.spacing(3),
			}}
		>
			<Typography
				variant="h4"
				style={{ marginTop: 50, textAlign: 'center' }}
			>
				Privacy policy
			</Typography>
			<br />
			<Typography
				variant="h6"
				style={{ textAlign: 'start', lineHeight: 1.3 }}
			>
				We use the Personal Information that we have about you to
				enforce the Terms and Service for the Site and this Privacy
				Policy; in connection with rewards programs; and to analyze Site
				usage to improve this Site.
			</Typography>
		</Container>
	);
};

export default PrivacyAndPolicy;
