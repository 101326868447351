import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
	palette: {
		background: {
			default: '#000225',
		},
		primary: {
			main: '#235EE1',
		},
		secondary: {
			main: '#19857b',
		},
		error: {
			main: red.A400,
		},
	},
	typography: {
		allVariants: {
			textTransform: 'none',
			color: 'white',
			lineHeight: 'initial',
		},
		fontFamily: 'Poppins, sans-serif',
	},
});

export default theme;
