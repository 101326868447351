const dataSet = {
	'1h': {
		title: 'volume',
		format: 'YYYY-MM-DD h:mma',
		xMark: '',
		yMark: 'K',
		data: [
			{ date: '2024-05-28 13:00:00', value: 91 },
			{ date: '2024-05-28 14:00:00', value: 111.42 },
			{ date: '2024-05-28 15:00:00', value: 118.43 },
			{ date: '2024-05-28 16:00:00', value: 116.6 },
			{ date: '2024-05-28 17:00:00', value: 113.66 },
			{ date: '2024-05-28 18:00:00', value: 98.99 },
			{ date: '2024-05-28 19:00:00', value: 90.58 },
			{ date: '2024-05-28 20:00:00', value: 105.67 },
			{ date: '2024-05-28 21:00:00', value: 108.02 },
			{ date: '2024-05-28 22:00:00', value: 101.39 },
			{ date: '2024-05-28 23:00:00', value: 97.82 },
			{ date: '2024-05-29 00:00:00', value: 104.3 },
			{ date: '2024-05-29 01:00:00', value: 118.89 },
			{ date: '2024-05-29 02:00:00', value: 91.02 },
			{ date: '2024-05-29 03:00:00', value: 117.96 },
			{ date: '2024-05-29 04:00:00', value: 92.22 },
			{ date: '2024-05-29 05:00:00', value: 99.88 },
			{ date: '2024-05-29 06:00:00', value: 94.13 },
			{ date: '2024-05-29 07:00:00', value: 109.61 },
			{ date: '2024-05-29 08:00:00', value: 99.76 },
			{ date: '2024-05-29 09:00:00', value: 106.21 },
			{ date: '2024-05-29 10:00:00', value: 99.22 },
			{ date: '2024-05-29 11:00:00', value: 99.91 },
			{ date: '2024-05-29 12:00:00', value: 104.19 },
		],
	},
	'7d': {
		title: 'volume',
		format: 'DD/MM/YYYY',
		xMark: '',
		yMark: 'M',
		data: [
			{
				date: '23/05/2024',
				value: 1.1,
			},
			{
				date: '24/05/2024',
				value: 1.4,
			},
			{
				date: '25/05/2024',
				value: 2.1,
			},
			{
				date: '26/05/2024',
				value: 1.8,
			},
			{
				date: '27/05/2024',
				value: 2.2,
			},
			{
				date: '28/05/2024',
				value: 2.0,
			},
			{
				date: '29/05/2024',
				value: 2.4,
			},
		],
	},
};

export { dataSet };
