// src/NotServedPage.js
import React from 'react';
import { Box, Container, Typography, useTheme } from '@mui/material';

const NotServedPage = () => {
	const theme = useTheme();

	return (
		<Container
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				minHeight: '100vh',
				backgroundColor: theme.palette.background.default,
				padding: theme.spacing(3),
			}}
		>
			<Box>
				<img
					src="/brand-logo.svg"
					style={{
						width: '350px',
						marginBottom: theme.spacing(2),
					}}
				/>
			</Box>
			<Typography
				variant="h5"
				style={{ marginTop: 40, textAlign: 'center', maxWidth: 650 }}
			>
				We are sorry that we cannot offer services in your region at
				this time. Our platform is currently restricted in the following
				regions:
				<br />
				<br />
				Belarus, Canada, Cuba, Iran, North Korea, Russia, Syria,
				Ukraine, United States of America, United Kingdom.
			</Typography>
		</Container>
	);
};

export default NotServedPage;
