import moment from 'moment';

export const hasOneHourPassed = (startTime: string) => {
	const currentTime = moment();
	const startTimeMoment = moment(startTime);

	const differenceInHours = currentTime.diff(startTimeMoment, 'hours');

	return differenceInHours < 1;
};
