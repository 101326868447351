import { all } from 'redux-saga/effects';

import assetPricesSaga from 'store/asset-prices/saga';
import poolDepositsSaga from 'store/pool-deposits/saga';
import walletBalancesSaga from 'store/wallet-balances/saga';
import depositsSaga from 'store/deposit/saga';
import withdrawalSaga from 'store/withdrawal/saga';

export default function* () {
	yield all([
		assetPricesSaga(),
		poolDepositsSaga(),
		walletBalancesSaga(),
		depositsSaga(),
		withdrawalSaga(),
	]);
}
