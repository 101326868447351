import React, { useState } from 'react';
import { ChartTabs } from 'pages/dashboard/components/ChartTabs';
import { Box } from '@mui/material';
import { PoolPnLChart } from 'pages/dashboard/components/PoolPnLChart';
import { SharpRatioBarCharts } from 'pages/dashboard/components/SharpRatioBarCharts';
import { styled } from '@mui/material/styles';

const Cover = styled(Box)`
	border: 1px solid #00001f;
	margin-top: 10px;
	border-radius: 4px;
	padding: 10px 10px 0 0;
`;

const Charts: React.ComponentType = () => {
	const [activeTab, setActiveTab] = useState(0);

	return (
		<Box>
			<ChartTabs
				activeTab={activeTab}
				setActiveTab={(active) => setActiveTab(active)}
			/>
			<Cover>
				{activeTab === 0 && <PoolPnLChart />}
				{activeTab === 1 && <SharpRatioBarCharts />}
			</Cover>
		</Box>
	);
};

export { Charts };
