import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';

export type WalletBalance = {
	token: string;
	balance: BigNumber;
};

export type WalletBalancesState = {
	fetching: boolean;
	balances: WalletBalance[];
};

const initialState: WalletBalancesState = {
	fetching: false,
	balances: [],
};

export const walletBalancesSlice = createSlice({
	name: 'walletBalances',
	initialState,
	reducers: {
		requestWalletBalances: (state: Draft<WalletBalancesState>) => {
			state.fetching = true;
		},
		requestWalletBalancesSuccess: (
			state: Draft<WalletBalancesState>,
			action: PayloadAction<WalletBalance[]>,
		) => {
			state.fetching = false;
			state.balances = action.payload;
		},
		requestWalletBalancesFailed: (
			state: Draft<WalletBalancesState>,
			_: PayloadAction,
		) => {
			state.fetching = false;
		},
	},
});

export const walletBalancesActions = walletBalancesSlice.actions;
const walletBalancesReducer = walletBalancesSlice.reducer;
export default walletBalancesReducer;
