// src/NotServedPage.js
import React from 'react';
import { Container, Typography, useTheme } from '@mui/material';

const TermsAndCondition = () => {
	const theme = useTheme();

	return (
		<Container
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				minHeight: '100vh',
				backgroundColor: theme.palette.background.default,
				padding: theme.spacing(3),
			}}
		>
			<Typography
				variant="h4"
				style={{ marginTop: 50, textAlign: 'center' }}
			>
				Terms of Use
			</Typography>
			<br />
			<Typography
				variant="h6"
				style={{ textAlign: 'start', lineHeight: 1.3 }}
			>
				<br />
				<br />
				By participating in this testing, you agree to the following
				terms and conditions:
				<br />
				<br />
				These Terms of Service (the “Terms”) constitute a legally
				binding agreement made between you, whether personally or on
				behalf of an entity (“you”), and VCRED Ltd. British Virgin
				Islands (“VCRED,” “we,” or “us”), concerning your access to and
				use of the https://test.vcred.trade website (the
				“Website”),VCRED smart contracts, and our related websites,
				hosted applications, AI models (collectively, the “Services”).
				By accessing or using the Services, you agree that you have
				read, understand, and accept all of the terms and conditions
				contained in the Terms. If you do not agree to all of the terms
				and conditions of the Terms, you may not access or use the
				Services.
				<br />
				<br />
				Amendment of the Terms: We may amend or modify the Terms at any
				time by posting the revised agreement on the Website and/or
				providing a copy to you (“Revised Terms”). Such Revised Terms
				shall be effective as of the time the Revised Terms are posted.
				Your continued use of the Services after the posting of Revised
				Terms constitutes your acceptance of such Revised Terms. If you
				do not agree with any such modifications, your sole and
				exclusive remedy is to terminate your use of the Services.
				<br />
				<br />
				* You represent and warrant that you are not a person and/or not
				representing a legal entity which resides in, is a citizen of,
				is located or incorporated in, has registered office in or is
				otherwise subject to the jurisdiction of the United States of
				America or any Prohibited Localities, and that you are not a
				Restricted Person (each as defined in these Terms). There are no
				exceptions, and you represent and warrant that you are not using
				a VPN and/or are not otherwise circumventing this prohibition.
				<br />
				<br />
				* You acknowledge that the Site Operator is not authorized or
				regulated by any financial market regulatory authority,
				including the United Kingdom Financial Conduct Authority (FCA).
				Nothing on this website is intended to constitute marketing or
				promotion, and the content of this website is not intended for
				UK consumers. Consumer protections under UK regulations do not
				apply, and else you agree to waive any protection afforded under
				FCA regulations. The Site Operator does not provide services to
				UK residents.
				<br />
				<br />
				* You must be permitted to use the Site and the VCRED Smart
				Contract System under applicable laws, and you must always
				comply with all applicable laws. Such applicable laws may vary
				depending on where you are domiciled, located, incorporated,
				operate out of, etc., and only you are responsible for ensuring
				full compliance.
				<br />
				<br />
				* You acknowledge and agree that the Site is provided for
				informational purposes only and neither the Site Operator nor
				any individual or group of the VCRED project team members,
				contributors, or similar are in control of or, have custody over
				your funds, the ability or duty to transact on your behalf or
				the power to reverse your transactions.
				<br />
				<br />
				* You acknowledge and agree that the Site does not constitute
				any financial advice and is only being provided 'as is' and 'as
				available' without any representation, warranties or guarantees
				whatsoever.
				<br />
				<br />
				* You acknowledge and agree that there is no legal or factual
				relationship between you and the Site Operator and/or any
				individual or group of the VCRED project team members,
				contributors, or similar relating to VCRED. The VCRED Smart
				Contract System may be accessed through a variety of means and
				access requires third party software (e.g., wallets) for which
				you are fully responsible.
				<br />
				<br />
				* You acknowledge and agree that blockchain and DeFi, including
				the VCRED Smart Contract System, are of novel and experimental
				nature and are associated with a variety of risks, which you
				have familiarized yourself with and accepted. The Site and the
				VCRED Smart Contract System are available 'as is' and 'as
				available' without any representation, warranties or guarantees
				whatsoever and the Site Operator is not, to the maximum extent
				permitted under applicable law, liable for any damages incurred
				by you in connection with the use of the Site or the VCRED Smart
				Contract System.
				<br />
				<br />
				* You, to the maximum extent permitted under applicable law,
				release all present and future claims against the Site Operator,
				and against any individual or group of VCRED project team
				members, contributors or similar related to the use of the VCRED
				Smart Contract System.
				<br />
				<br />
				* Points are provided by third parties such as BSX and we have
				no control over the equivalent value of these points to crypto
				or equivalent denominations. The numbers shown in the website
				are suggested as reference only.
				<br />
				<br />
				* You acknowledge that the Services incorporate experimental and
				novel technology and that the use of such technology involves a
				high degree of risk. For example, there are numerous reasons the
				Services and/or Protocol could fail in an unexpected way,
				resulting in the total and absolute loss of your crypto assets.
				You hereby agree that you assume all risks in connection with
				your use of the Services and expressly waive and release VCRED
				from any and all liability, claims, causes of action or damages
				arising out of or in any way relating to you obtaining or using
				Services.
				<br />
				<br />
				* By entering into these Terms, you waive your potential right
				to participate in class actions and agree that any disputes are
				resolved pursuant to binding, confidential arbitration, whereby
				your potential right to a jury trial is waived.
				<br />
			</Typography>
		</Container>
	);
};

export default TermsAndCondition;
