import { PayloadAction } from '@reduxjs/toolkit';
import { call, fork, put, select, take } from 'redux-saga/effects';
import type { InitiateWithdrawalParams } from 'store/withdrawal/index';
import { withdrawalActions } from 'store/withdrawal/index';
import { getContract } from 'utils/contractHelpers';
import { ethers } from 'ethers';
import { SMMPool } from 'data';
import { RootState } from 'store/index';
import { toast } from 'react-toastify';

const withdraw = async (params: InitiateWithdrawalParams) => {
	const { asset, amount, library, account } = params;

	if (!asset.poolContractAddr.Base) {
		return;
	}

	const withdrawalContract = getContract(
		asset.poolAbis,
		asset.poolContractAddr.Base,
		library.getSigner(),
	);

	const parsedAmount = ethers.utils.parseUnits(amount, asset.decimals);

	// calculate total shares
	const balances = await withdrawalContract.balances(account);
	const totalShares = balances.share;

	// calculate total deposit
	const totalDepositInTokens =
		await withdrawalContract.getDepositTokensForShares(totalShares);

	// fractional shares = amountToWithdraw * totalShares /totalDeposit
	const fractionalShares = parsedAmount
		.mul(totalShares)
		.div(totalDepositInTokens);

	const tx = await withdrawalContract.withdraw(fractionalShares);
	const receipt = await tx.wait();
};

function* initiateWithdrawal(params: InitiateWithdrawalParams) {
	const { library, account } = params;

	try {
		const activePool: SMMPool = yield select(
			(state: RootState) => state.activePool.pool,
		);

		yield call(withdraw, params);

		toast('Withdrawal successful', {
			type: 'success',
		});
		yield put(
			withdrawalActions.withdrawalSuccess({
				library,
				account,
				activePoolId: activePool.id,
			}),
		);
	} catch (error) {
		toast('Withdrawal failed', {
			type: 'error',
		});
		yield put(withdrawalActions.withdrawalFailed());
	}
}

export default function* saga() {
	while (true) {
		const action: PayloadAction<InitiateWithdrawalParams> = yield take(
			withdrawalActions.withdraw.type,
		);

		yield fork(initiateWithdrawal, action.payload);

		yield take([
			withdrawalActions.withdrawalSuccess.type,
			withdrawalActions.withdrawalFailed.type,
		]);
	}
}
