import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { useWeb3React } from '@web3-react/core';

import { css } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';
import { DepositDialog } from 'pages/dashboard/components/DepositModal';
import { WidthdrawModal } from 'pages/dashboard/components/WithdrawModal';

const MainButton = styled(Button)`
	${css`
		background-color: transparent;
		color: white;
		border: 2px solid #b8cdf5;
		padding: 6px 16px;
		border-radius: 4px;
		margin-left: 10px;
	`}
`;

const DisconnectedView: React.ComponentType = () => {
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
			}}
		>
			<img src="/icons/wallet.png" height={20} />
			<Box sx={{ width: 8 }} />
			<Typography
				sx={{
					fontWeight: 'bold',
				}}
			>
				Connect
			</Typography>
		</Box>
	);
};

const DepositView: React.ComponentType = () => {
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
			}}
		>
			<img src="/deposit.png" height={20} />
			<Box sx={{ width: 8 }} />
			<Typography
				sx={{
					fontWeight: 'bold',
				}}
			>
				Deposit
			</Typography>
		</Box>
	);
};

const DepositButton: React.ComponentType = () => {
	const [isWithdraw, setIsWithdraw] = useState<boolean>(false);
	const { account, library } = useWeb3React();

	const [isVisible, setIsVisible] = useState(false);

	const Balance: React.ComponentType = () => {
		return (
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<img src="/icons/wallet.png" height={20} />
				<Box sx={{ width: 8 }} />
				<Typography
					sx={{
						fontWeight: 'bold',
					}}
				>
					Withdraw
				</Typography>
			</Box>
		);
	};

	return (
		<div>
			{account ? (
				<MainButton onClick={() => setIsWithdraw(true)}>
					<Balance />
				</MainButton>
			) : (
				<></>
			)}
			{account ? (
				<MainButton onClick={() => setIsVisible(true)}>
					<DepositView />
				</MainButton>
			) : (
				<></>
			)}
			<DepositDialog
				isOpen={isVisible}
				onClose={() => setIsVisible(false)}
			/>
			<WidthdrawModal
				isOpen={isWithdraw}
				onClose={() => setIsWithdraw(false)}
			/>
		</div>
	);
};

export { DepositButton };
