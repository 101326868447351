import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import { activePoolActions } from 'store/active-pool';
import { sMMPools } from 'data';
import { Box, Button, GlobalStyles } from '@mui/material';
import styled from '@emotion/styled';
import { MenuBar } from 'pages/dashboard/components/MenuBar';
import { YourAsset } from 'pages/dashboard/components/YourAsset';
import { CurrentState } from 'pages/dashboard/components/CurrentState';
import { StrategyInfo } from 'pages/dashboard/components';
import { PoolPnLChart } from 'pages/dashboard/components/PoolPnLChart';

const PageLayout = styled(Box)`
	display: grid;
	grid-template-columns: 270px auto 350px;
	grid-auto-rows: min-content;
	column-gap: 30px;
	row-gap: 40px;
	min-height: 95vh;
	max-width: 100%;
	padding-right: 70px;

	grid-template-areas:
		'Menu Heading Heading' 'Menu Benifit Benifit' 'Menu Charts Charts'
		'Menu YourAsset YourAsset';

	@media (max-width: 1350px) {
		grid-template-columns: 270px auto 350px;
		grid-template-rows: min-content;
		grid-template-areas: 'Menu Heading Heading' 'Menu Benifit Benifit' 'Menu Stats Stats' 'Menu Charts Charts' 'Menu YourAsset YourAsset';
	}

	@media (max-width: 1200px) {
		padding-right: 0;
		grid-template-columns: auto;
		grid-template-rows: min-content min-content min-content min-content;
		grid-template-areas: 'Menu' 'Heading' 'Benifit' 'Stats' 'Charts' 'YourAsset';
	}

	@media screen and (max-width: 800px) {
		padding-bottom: 400px;
	}
`;

const YourAssetWrapper = styled('div')`
	grid-area: YourAsset;
	filter: blur(5px) grayscale(100%);

	@media screen and (max-width: 1200px) {
		margin-left: 20px;
		margin-right: 20px;
	}
`;

const Menu = styled(Box)`
	grid-area: Menu;
	background-color: #00001f;
	min-height: 95vh;
	padding-top: 30px;
	padding-left: 15px;

	@media screen and (max-width: 1200px) {
		padding: 20px 0 20px 20px;
		min-height: min-content;
	}

	@media screen and (max-width: 550px) {
		padding: 5px;
		min-height: min-content;
	}
`;

const Heading = styled(Box)`
	grid-area: Heading;
	margin-top: 40px;

	@media screen and (max-width: 1200px) {
		margin-top: initial;
		margin-left: 20px;
	}

	@media screen and (max-width: 800px) {
		margin-right: 20px;
	}
`;

const BenifitsWrapper = styled(Box)`
	grid-area: Benifit;

	@media screen and (max-width: 1200px) {
		margin-left: 20px;
		margin-right: 20px;
	}
`;

const Ganesh = styled(Box)`
	display: flex;
	flex-direction: column;
	grid-area: Ganesh;
	align-items: center;
	width: 100%;
	height: 100%;
	justify-content: center;

	@media screen and (max-width: 1350px) {
		margin-left: 20px;
	}

	@media screen and (max-width: 800px) {
		grid-column: 1 / span 2;
		margin-right: 20px;
	}
`;

const Stats = styled(Box)`
	grid-area: Stats;

	@media screen and (max-width: 1350px) {
		margin-left: 20px;
	}

	@media screen and (max-width: 800px) {
		grid-column: 1 / span 2;
		margin-right: 20px;
	}
`;

const WrappedButton = styled(Button)`
	height: 52px;
	background: rgb(151, 252, 228);
	border-radius: 26px;

	:hover {
		background-color: #76cfc1;
	}
`;

const ChartsPanel = styled(Box)`
	grid-area: Charts;

	@media screen and (max-width: 1350px) {
		margin-left: 20px;
		margin-right: 20px;
	}
`;

const DocPanel = styled(Box)`
	grid-area: DocPanel;

	@media screen and (max-width: 1350px) {
		margin-left: 20px;
	}

	@media screen and (max-width: 1200px) {
		grid-column: 1 / span 2;
	}

	@media screen and (max-width: 800px) {
		margin-right: 20px;
	}
`;

const Panel = styled(Box)`
	grid-area: Panel;
	margin-top: 40px;

	@media screen and (max-width: 800px) {
		position: fixed;
		bottom: 0;
		right: 1%;

		width: 98%;
	}
`;

const Image = styled.img`
	height: 160px;
	width: 100%;
	object-fit: contain;
`;

const Image2 = styled.img`
	height: 100px;
	width: 100%;
	object-fit: contain;
`;

const Cover = styled(Box)`
	border: 1px solid #00001f;
	margin-top: 10px;
	border-radius: 4px;
`;

/*const StyledBox = styled(Box)`
	background: #00001f;
	border-radius: 6px;
	border: 1px solid #f6fcff;
`;*/

const StyledBox = styled(Box)`
	background: #00001f;
	border-radius: 6px;
	border: 1px solid #f6fcff;
	width: 100%;
	max-width: 100%;
	overflow: auto;
`;

const Dashboard: React.ComponentType = () => {
	const activePool = useAppSelector((state) => state.activePool.pool);

	const dispatch = useAppDispatch();
	const { search } = useLocation();

	// take out the strategy from the url
	const query = useMemo(() => new URLSearchParams(search), [search]);

	// dispatch the strategy to the store
	useEffect(() => {
		const strategyId = query.get('strategy') || sMMPools[0].id;
		dispatch(activePoolActions.setActivePool(strategyId));
	}, [dispatch, query]);

	if (!activePool) {
		return null;
	}

	return (
		<PageLayout>
			<GlobalStyles
				styles={{
					'html, body, #root': {
						backgroundColor: '#1A1A33',
					},
				}}
			/>

			<Menu>
				<MenuBar />
			</Menu>

			<Heading>
				<StrategyInfo />
			</Heading>

			<BenifitsWrapper>
				<CurrentState />
			</BenifitsWrapper>

			{/*<Benefits />*/}

			{/*{activePool.id !== 'ganesh' && (
				<Stats>
					<PersonalStats />
				</Stats>
			)}*/}

			<ChartsPanel>
				<StyledBox
					style={{
						maxWidth:
							window.innerWidth < 800
								? window.innerWidth
								: '100%',
					}}
				>
					<Cover>
						<PoolPnLChart />
					</Cover>
				</StyledBox>
			</ChartsPanel>

			{/*{activePool.id !== 'ganesh' && (
				<ChartsPanel>
					<Chart1 />
					<Gutter spacing={2} />
					<Chart2 />
				</ChartsPanel>
			)}*/}

			{/*{activePool.id !== 'ganesh' && (
				<DocPanel>
					<DetailPanel />
				</DocPanel>
			)}*/}
			<YourAssetWrapper>
				<YourAsset />
			</YourAssetWrapper>

			{/*<Panel>
				<DepositWithdrawPanel />
			</Panel>*/}
		</PageLayout>
	);
};

export { Dashboard };
