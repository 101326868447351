import { takeLatest, put } from 'redux-saga/effects';
import { assetPriceActions } from './index';

function* fetchAssetPrices() {
	try {
		// call async api
		/**
		 * yield all([
		 * 	call(api1),
		 * ])
		 * */

		yield put(assetPriceActions.assetPricesReceived([]));
	} catch (error) {
		// something failed, fire negative action
		// yield put(actions.action())
	}
}

export default function* saga() {
	yield takeLatest(assetPriceActions.fetchAssetPrices.type, fetchAssetPrices);
}
