import React, { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { sMMPools } from 'data';
import Select, { components, OptionProps } from 'react-select';
import { StylesConfig } from 'react-select/dist/declarations/src/styles';
import { ControlProps } from 'react-select/dist/declarations/src/components/Control';
import { Box } from '@mui/material';
import { activePoolActions } from 'store/active-pool';

const selectStyles: StylesConfig = {
	container: (baseStyles) => ({
		...baseStyles,
		background: 'transparent',
		//background: '#1A1A33',
		height: 56,
	}),
	control: (baseStyles, state) => ({
		...baseStyles,
		borderColor: '#FBFBFB', //state.isFocused ? '#235EE1' : '#235EE1',
		background: 'transparent', //'#1A1A33',
		height: 56,
		'&:hover': {
			borderColor: state.isFocused
				? '#FBFBFB' /*'#235EE1'*/
				: '#FBFBFB' /*'#235EE1'*/,
		},
	}),
	menu: (baseStyles) => ({
		...baseStyles,
		//background: 'transparent',
		background: '#1A1A33',
		borderRadius: 4,
	}),
	option: (baseStyles, state) => ({
		...baseStyles,
		backgroundColor: state.isDisabled
			? undefined
			: state.isSelected
			? '#FBFBFB' //'#235EE1'
			: state.isFocused
			? '#2d3750'
			: undefined,
	}),
	singleValue: (baseStyles) => ({
		...baseStyles,
		color: '#fff',
		fontWeight: 500,
	}),
};

const Control = ({
	selectedIcon,
	children,
	...props
}: { selectedIcon: string } & ControlProps) => {
	return (
		<components.Control {...props}>
			<Box
				sx={{
					padding: '0 10px',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<img height={30} src={selectedIcon} />
			</Box>
			{children}
		</components.Control>
	);
};

const Option = ({ children, ...props }: OptionProps) => {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/ban-ts-comment
	// @ts-ignore
	const icon = props.data.icon;

	return (
		<components.Option {...props}>
			<Box display="flex" alignItems="center">
				<img height={30} src={icon} />
				<Box width={20} />
				{children}
			</Box>
		</components.Option>
	);
};

const AssetDropdownSelect: React.ComponentType = () => {
	const depositing = useAppSelector((state) => state.deposits.depositing);
	const withdrawing = useAppSelector(
		(state) => state.withdrawals.withdrawing,
	);

	const dispatch = useAppDispatch();
	let activePoolTokens = useAppSelector(
		(state) => state.activePool.pool?.tokens,
	);
	let activeToken = useAppSelector((state) => state.activePool.token);

	if (!activePoolTokens) activePoolTokens = sMMPools[0].tokens;
	if (!activeToken) activeToken = sMMPools[0].tokens[0];

	const options = useMemo(() => {
		return (activePoolTokens || []).map((token) => ({
			value: token.symbol,
			label: token.name,
			icon: token.icon,
		}));
	}, [activePoolTokens]);

	const onTokenSwitch: any = useCallback(
		(newTokenConfig: { value: string }) => {
			dispatch(
				activePoolActions.switchActiveToken({
					symbol: newTokenConfig.value,
				}),
			);
		},
		[dispatch],
	);

	return (
		<Select
			isDisabled={depositing || withdrawing}
			value={{
				label: activeToken.symbol,
			}}
			options={options}
			onChange={onTokenSwitch}
			isSearchable={false}
			styles={selectStyles}
			components={{
				Control: (props) => (
					<Control
						selectedIcon={activeToken?.icon || ''}
						{...props}
					/>
				),
				Option: (props) => <Option {...props} />,
			}}
		/>
	);
};

export { AssetDropdownSelect };
