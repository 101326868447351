import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { useCallback, useEffect, useState } from 'react';
import { ChainNames, ConnectorNames } from 'config';
import { connectorByName } from 'utils/web3React';
import {
	NoEthereumProviderError,
	UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import {
	UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
	WalletConnectConnector,
} from '@web3-react/walletconnect-connector';
import { setupNetwork } from 'utils/wallet';
import { toast } from 'react-toastify';

const useAuth = () => {
	const { account, activate, deactivate } = useWeb3React();
	const [allAccounts, setAllAccounts] = useState<string[]>([]);

	const login = useCallback(
		async (connectorId: ConnectorNames, network: ChainNames) => {
			localStorage.setItem('connectorId', connectorId);
			localStorage.setItem('networkId', network);

			const connectorOrGetConnector = connectorByName(
				connectorId,
				network,
			);

			const connector =
				typeof connectorOrGetConnector !== 'function'
					? connectorOrGetConnector
					: await connectorOrGetConnector();

			if (typeof connector !== 'function' && connector) {
				activate(connector, async (error: Error) => {
					if (error instanceof UnsupportedChainIdError) {
						const provider = await connector.getProvider();

						const hasSetup = await setupNetwork(network, provider);

						if (hasSetup) {
							activate(connector);
						} else {
							localStorage.removeItem('connectorId');
							localStorage.removeItem('networkId');
						}
					} else {
						localStorage.removeItem('connectorId');
						localStorage.removeItem('networkId');

						if (error instanceof NoEthereumProviderError) {
							toast('No wallet found', {
								type: 'error',
							});
						} else if (
							error instanceof UserRejectedRequestErrorInjected ||
							error instanceof
								UserRejectedRequestErrorWalletConnect
						) {
							if (connector instanceof WalletConnectConnector) {
								connector.walletConnectProvider = null;
							}

							toast('Please allow access to your account', {
								type: 'error',
							});
						} else {
							toast(
								error.message ||
									'Unknown error. Please try again.',
								{
									type: 'error',
								},
							);
						}
					}
				});
			} else {
				toast('Unable to connect to wallet', {
					type: 'error',
				});
			}
		},
		[activate],
	);

	const logout = useCallback(() => {
		try {
			if (window?.ethereum?.request) {
				window.ethereum.request({
					method: 'wallet_revokePermissions',
					params: [
						{
							eth_accounts: {},
						},
					],
				});
			} else {
				//console.log('Unable to revoke');
			}

			deactivate();

			localStorage.removeItem('connectorId');
			localStorage.removeItem('networkId');
			setAllAccounts([]);
		} catch (e) {
			//
		}
	}, [deactivate]);

	useEffect(() => {
		if (account && !allAccounts.includes(account)) {
			setAllAccounts((prevAccounts) => [...prevAccounts, account]);
		}
	}, [account, allAccounts]);

	return { login, logout, allAccounts };
};

export { useAuth };
