import React from 'react';
import { styled } from '@mui/material/styles';
import { FlexCol, FlexRow } from 'components/Flex';
import { Box, Typography } from '@mui/material';

const Grid = styled('div')`
	display: grid;
	width: 100%;
	grid-template-columns: 2fr 1fr;
	column-gap: 10px;
	row-gap: 10px;

	@media screen and (max-width: 850px) {
		grid-template-columns: 1fr;
	}
`;

const AssetWrapper = styled('div')`
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr;
	column-gap: 10px;
	row-gap: 10px;

	@media screen and (max-width: 1300px) {
		grid-template-columns: 1fr;
	}
`;

const Row = styled(FlexRow)`
	width: 100%;
	border-radius: 6px;
	background: #33334a;
	padding: 8px;
	align-items: center;
`;

const Item = styled(FlexRow)`
	padding: 18px 10px;
	width: 100%;
	min-width: 200px;
	border-radius: 7px;
	overflow: hidden;
	border: 1px solid #d6d6d6;
	align-items: center;
	background: transparent;
`;

const ItemText = styled(FlexCol)`
	flex: 1;
	padding-left: 10px;
	justify-content: center;
	align-items: flex-start;
`;

const Wrapper = styled(FlexRow)`
	width: 100%;
	align-items: center;
	//padding: 10px;
`;

export const YourAsset = () => {
	return (
		<>
			<Grid>
				<FlexCol>
					<Wrapper>
						<Item>
							<FlexRow>
								<img
									src={'/dashboard/usd.png'}
									width={75}
									height={75}
								/>
								<Box width={16} />
								<ItemText>
									<Typography
										variant={'subtitle1'}
										fontWeight={500}
									>
										User Capital
									</Typography>
									<Box height={8} />
									<Typography variant={'h4'} fontWeight={500}>
										$10,000
									</Typography>
								</ItemText>
							</FlexRow>
						</Item>
					</Wrapper>
					<Box height={10} />
					<AssetWrapper>
						<Item>
							<FlexRow>
								<img
									src={'/dashboard/vcred.png'}
									width={50}
									height={70}
									style={{ marginLeft: 10 }}
								/>
								<Box width={16} />
								<ItemText>
									<Typography
										variant={'subtitle1'}
										fontWeight={500}
									>
										VCRED POINTS
									</Typography>
									<Box height={8} />
									<Typography variant={'h4'} fontWeight={500}>
										10,000
									</Typography>
								</ItemText>
							</FlexRow>
						</Item>
						<Item>
							<FlexRow>
								<img
									src={'/dashboard/percent.png'}
									width={75}
									height={75}
								/>
								<Box width={16} />
								<ItemText>
									<Typography
										variant={'subtitle1'}
										fontWeight={500}
									>
										EST YIELD
									</Typography>
									<Box height={8} />
									<Typography variant={'h4'} fontWeight={500}>
										23.79%
									</Typography>
								</ItemText>
							</FlexRow>
						</Item>
					</AssetWrapper>
				</FlexCol>

				<FlexCol>
					<Wrapper>
						<Item
							style={{
								flexDirection: 'column',
								alignItems: 'flex-start',
							}}
						>
							<Typography>Partner Points</Typography>
							<Box height={8} />
							<Row>
								<img
									src={'/dashboard/hubble.png'}
									width={35}
									height={35}
								/>
								<Box width={16} />
								<Typography variant={'h5'}>589</Typography>
							</Row>
							<Box height={8} />
							<Row>
								<img
									src={'/dashboard/hyperliquid.png'}
									width={35}
									height={35}
								/>
								<Box width={16} />
								<Typography variant={'h5'}>667</Typography>
							</Row>
							<Box height={8} />
							<Row>
								<img
									src={'/dashboard/hyperliquid.png'}
									width={35}
									height={35}
								/>
								<Box width={16} />
								<Typography variant={'h5'}>987</Typography>
							</Row>
						</Item>
					</Wrapper>
				</FlexCol>
			</Grid>
			<Box height={50} />
		</>
	);
};
