import React from 'react';
import { FlexRow } from 'components/Flex';
import styled from '@emotion/styled';
import { CHART_TABS } from 'data/sMMCharts/chartTabs';
import { Gutter } from 'components/Gutter';
import { Typography } from '@mui/material';

const Tab = styled(FlexRow)<{ isActive: boolean }>`
	padding: 15px 20px;
	border-radius: 7px;
	font-size: 14px;
	font-weight: 600;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	background-color: ${(props) => (props.isActive ? '#000' : 'transparent')};
`;

const Box = styled(FlexRow)<{ isActive: boolean }>`
	padding: 2px 5px;
	border-radius: 4px;
	background-color: ${(props) => (props.isActive ? '#ffffff' : '#999')};
	color: ${(props) => (props.isActive ? '#000' : '#333')};
`;

type Props = {
	activeTab: number;
	setActiveTab: (tab: number) => void;
};

export const ChartTabs: React.ComponentType<Props> = ({
	activeTab,
	setActiveTab,
}) => {
	return (
		<FlexRow>
			{CHART_TABS.map((t) => (
				<Tab
					key={t.id}
					isActive={t.id === activeTab}
					onClick={() => setActiveTab(t.id)}
				>
					<Typography
						variant={'subtitle1'}
						color={t.id === activeTab ? '#fff' : '#999'}
					>
						{t.name}
					</Typography>
					<Gutter gap={0.6} />
					{t.tag.map((m) => (
						<Box key={m} isActive={activeTab === t.id}>
							{m}
						</Box>
					))}
				</Tab>
			))}
		</FlexRow>
	);
};
