import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { DepositPanel } from 'pages/dashboard/components/DepositPanel';

export interface DialogTitleProps {
	id: string;
	children?: React.ReactNode;
	onClose: () => void;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
		border: '1px solid #E3E3E3',
	},
}));

type DepositModalProps = {
	isOpen: boolean;
	onClose?(): void;
};

const StyledDialogT = styled(DialogTitle)`
	border-top: 1px solid #e3e3e3;
	border-left: 1px solid #e3e3e3;
	border-right: 1px solid #e3e3e3;
`;

function StyledDialogTitle(props: DialogTitleProps) {
	const { children, onClose } = props;

	return (
		<StyledDialogT sx={{ m: 0, p: 2 }}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</StyledDialogT>
	);
}

const DepositDialog = ({ isOpen, onClose }: DepositModalProps) => {
	return (
		<StyledDialog
			onClose={() => onClose?.()}
			aria-labelledby="customized-dialog-title"
			open={isOpen}
			maxWidth="xs"
			PaperProps={{
				style: {
					background:
						'linear-gradient(333deg, #1A1A33 44.85%, #B8CDF5 121.45%), linear-gradient(0deg, #00001F 0%, #00001F 100%), linear-gradient(252deg, #9BB6EF -1.34%, rgba(155, 182, 239, 0.00) 93.97%)',
					boxShadow: 'none',
				},
			}}
		>
			<StyledDialogTitle
				id="customized-dialog-title"
				onClose={() => onClose?.()}
			>
				<Typography
					color={'white'}
					variant={'subtitle1'}
					fontFamily={'DM Mono, sans-serif'}
				>
					Choose Deposit Amount
				</Typography>
			</StyledDialogTitle>

			<DialogContent
				dividers
				sx={{
					display: 'flex',
					flexDirection: 'column',
					flexWrap: 'wrap',
					borderBottomWidth: 0,
					borderColor: '#E3E3E3',
				}}
			>
				<DepositPanel onClose={onClose} />
			</DialogContent>
		</StyledDialog>
	);
};

export { DepositDialog };
