import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import type { PoolAsset } from 'data';
import { RequestDepositPayload } from 'store/pool-deposits';

export type InitiateDepositParams = {
	account: string;
	library: any;
	amount: string;
	asset: PoolAsset;
};

export type DepositState = {
	depositing: boolean;
};

const initialState: DepositState = {
	depositing: false,
};

export const depositSlice = createSlice({
	name: 'deposit',
	initialState,
	reducers: {
		deposit: (
			state: Draft<DepositState>,
			action: PayloadAction<InitiateDepositParams>,
		) => {
			state.depositing = true;
		},
		depositSuccess: (
			state: Draft<DepositState>,
			_: PayloadAction<RequestDepositPayload>,
		) => {
			state.depositing = false;
		},
		depositFailed: (state: Draft<DepositState>) => {
			state.depositing = false;
		},
	},
});

export const depositActions = depositSlice.actions;
const depositReducer = depositSlice.reducer;
export default depositReducer;
