import React, { useCallback, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from 'hooks';
import { poolDepositsActions } from 'store/pool-deposits';
import { useWeb3React } from '@web3-react/core';
import { roundTo } from 'utils/roundTo';

const ContentBox = styled(Box)`
	border: 1px solid #002581;
	background-color: #00001f;
	padding: 12px 16px;
`;

const DottedGutter = styled(Box)`
	margin: 12px 0;
	border-top: 1px dashed #6a7cb0;
	opacity: 0.7;
`;

const AmountLabel = styled(Typography)`
	font-size: 22px;
	font-weight: 700;
`;

const AssetLabel = styled(Typography)`
	font-size: 14px;
`;

const YourDeposit: React.ComponentType = () => {
	const { account, library } = useWeb3React();
	const dispatch = useAppDispatch();

	const activePool = useAppSelector((state) => state.activePool.pool?.id);
	const deposits = useAppSelector((state) => state.poolDeposits.deposits);

	useEffect(() => {
		if (activePool && library && account)
			dispatch(
				poolDepositsActions.requestDeposits({
					library,
					account,
					activePoolId: activePool,
				}),
			);
	}, [account, activePool, dispatch, library]);

	const roundedValue = useCallback((value: string) => {
		return `${roundTo(parseFloat(value), 4).toString()}`;
	}, []);

	return (
		<ContentBox>
			<Box display="flex" alignItems="center">
				<Typography color="#CCCCD1" fontSize="14px" fontWeight="700">
					Your Deposits
				</Typography>
				{/*<Box width={8} />
				<Typography color="#80808E" fontSize="12px" fontWeight="700">
					24 h
				</Typography>*/}
			</Box>

			<Box height={22} />

			{deposits.map((deposit, index) => (
				<Box key={index}>
					<Box display="flex" alignItems="baseline">
						<AmountLabel>
							{deposit.deposit
								? roundedValue(deposit.deposit)
								: ''}
						</AmountLabel>
						<Box width={4} />
						<AssetLabel>{deposit.token}</AssetLabel>
					</Box>

					{index < deposits.length - 1 && <DottedGutter />}
				</Box>
			))}
		</ContentBox>
	);
};

export { YourDeposit };
