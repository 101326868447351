import { Contract } from 'ethers';
import { Web3Provider } from '@ethersproject/providers';
import contractABI from 'abis/agentContract.json';

export const getDepositContract = (
	address: string,
	library: Web3Provider,
	account?: string,
): Contract => {
	return new Contract(
		address,
		contractABI,
		library.getSigner(account).connectUnchecked(),
	);
};
