import React from 'react';
import styled from '@emotion/styled';
import { FlexCol, FlexRow } from 'components/Flex';
import { SMMPool } from 'data';
import { Box, Typography } from '@mui/material';

const Item = styled(FlexRow)<{ isActive: boolean }>`
	padding: 8px;
	width: 100%;
	min-width: 200px;
	max-width: 238px;
	border-radius: 7px;
	overflow: hidden;
	border: ${({ isActive }) =>
		isActive ? '1px solid #235ee1' : '1px solid #D6D6D6'};
	opacity: ${({ isActive }) => (isActive ? 1 : 0.8)};
	align-items: center;
	background: ${({ isActive }) =>
		isActive
			? 'linear-gradient(333deg, #1a1a33 44.85%, #b8cdf5 121.45%)'
			: 'transparent'};

	@media (max-width: 1350px) {
		height: 100%;
		width: 100%;
		min-width: 230px;
		max-width: 250px;
	}

	:hover {
		opacity: 1;
	}
`;

const ItemText = styled(FlexCol)`
	flex: 1;
	padding-left: 10px;
	justify-content: center;
	align-items: flex-start;
`;

const Image = styled.img`
	width: 52px;
	height: 52px;
	object-fit: contain;
`;

const Wrapper = styled(FlexRow)`
	margin: 0 0 10px 0;
	align-items: center;

	@media (min-width: 1351px) {
		min-width: 230px;
		margin: 0 0 10px 0;
		flex-direction: column;
	}

	@media (max-width: 1200px) {
		min-width: 250px;
		flex-direction: column;
		margin: 0 10px 0 0;
	}

	:hover {
		cursor: pointer;
	}
`;

type Props = {
	isActive: boolean;
	smmPool: SMMPool;
	onClick: () => void;
};

export const MenuItem: React.ComponentType<Props> = ({
	isActive,
	smmPool,
	onClick,
}) => {
	const {
		illustration: { icon, whiteIcon },
	} = smmPool;

	return (
		<Wrapper onClick={onClick}>
			<Item isActive={isActive}>
				<Image
					src={isActive ? icon : whiteIcon}
					style={{
						background: isActive
							? smmPool.backgroundColor
							: 'transparent',
					}}
				/>
				<ItemText>
					<Typography variant={'h6'} fontWeight={500}>
						{smmPool.name}
					</Typography>
					<Box height={8} />
					<FlexRow>
						{smmPool.vaults.map((vault) => (
							<img
								width={20}
								height={20}
								key={vault.alt}
								alt={vault.alt}
								src={isActive ? vault.icon : vault.whiteIcon}
								style={{ marginRight: 8 }}
							/>
						))}
					</FlexRow>
				</ItemText>
			</Item>
		</Wrapper>
	);
};
