import React from 'react';
import { Box, Button, Card, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { Gutter } from 'components/Gutter';
import { SMMPool } from 'data';
import { FlexCol, FlexRow } from 'components/Flex';

const APRBox = styled('div')`
	display: grid;
	grid-template-columns: 1fr 1fr;
	border-radius: 16px;
	padding: 16px;
	background-color: #33334a;
	width: -webkit-fill-available;
	max-width: 100%;
	min-height: 45px;
	max-height: 75px;
	overflow: hidden;
`;

const Image = styled.img`
	height: 180px;
	width: 100%;
	object-fit: contain;
`;

const StyledCard = styled(Card)`
	position: relative;
	max-width: 360px;
	min-width: 350px;
	width: 100%;
	background: #1a1a33;
	border: 1px solid #235ee1;
	box-shadow: 0 4px 4px #000000;
	border-radius: 12px;
	height: 100%;

	:hover {
		opacity: 0.8;
	}
`;

const Item = styled('div')`
	background: linear-gradient(
			180deg,
			rgba(251, 251, 251, 0) 37.47%,
			rgba(207, 221, 248, 0.6) 62.45%
		),
		#fbfbfb;
	width: 100%;
	padding: 20px 0;
`;

const Col = styled(FlexCol)`
	width: 100%;
`;

const StyledButton = styled(Button)`
	:disabled {
		background: #2a2a2a;
	}
`;

const ActionArea = styled('div')`
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const Content = styled('div')`
	height: 100%;
	display: flex;
	flex: 1;
	padding: 16px;
`;

export const PoolItem = ({
	illustration,
	name,
	description,
	vaults,
	route,
	tags,
	disabled,
}: SMMPool) => {
	return (
		<StyledCard>
			<ActionArea>
				<Item>
					<Image src={illustration.icon} alt="pool" />
				</Item>
				<Content>
					<Col justify={'space-between'}>
						<Col>
							<FlexRow>
								{vaults.map((d) => (
									<img
										key={d.alt}
										width={24}
										height={24}
										alt={d.alt}
										src={d.icon}
										style={{ marginRight: 8 }}
									/>
								))}
							</FlexRow>
							<Box height={16} />
							{!disabled && (
								<>
									<Typography
										gutterBottom
										variant="h4"
										component="div"
										color={'#fff'}
										fontWeight={500}
									>
										{name}
									</Typography>
									<Typography
										variant={'body1'}
										color={'#FBFBFB'}
										style={{
											lineHeight: '140%',
											fontFeatureSettings: 'calt off',
										}}
									>
										{description}
									</Typography>
									<Gutter spacing={0.8} />
								</>
							)}
							{disabled && (
								<Typography
									gutterBottom
									variant="h4"
									component="div"
									color={'#fff'}
									fontWeight={500}
								>
									Coming Soon
								</Typography>
							)}
						</Col>
						<Col>
							<APRBox>
								{!disabled &&
									tags.map((tag) => (
										<FlexCol key={tag.title}>
											<Typography
												variant={'caption'}
												color={'#fff'}
											>
												{tag.title.toUpperCase()}
											</Typography>
											<Gutter spacing={0.5} />
											<Typography
												variant={'h6'}
												color={'#fff'}
											>
												{tag.value}
											</Typography>
										</FlexCol>
									))}
							</APRBox>
							<Gutter />
							<StyledButton
								variant={'contained'}
								style={{ width: '100%', padding: 16 }}
								disabled={disabled}
							>
								<Typography
									variant={'subtitle1'}
									fontWeight={500}
									color={disabled ? '#666666' : '#fff'}
								>
									{route.title}
								</Typography>
							</StyledButton>
						</Col>
					</Col>
				</Content>
			</ActionArea>
		</StyledCard>
	);
};
