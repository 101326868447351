import React, { Dispatch, SetStateAction } from 'react';
import { Button, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { FlexCol, FlexRow } from 'components/Flex';

const Text = styled(Typography)`
	:hover {
		cursor: pointer;
	}
`;

const DisclaimerWrapper = styled(FlexCol)`
	max-height: 200px;
	width: 100%;
	overflow: scroll;
	flex-wrap: nowrap;
	padding: 10px;
	border-radius: 10px;
	border: 1px solid #235ee1;
`;

const StyledButton = styled(Button)`
	width: 100%;
	padding: 14px;
`;

const StyledWrapper = styled(FlexCol)`
	width: 100%;
	min-width: 300px;
	max-width: 350px;
`;

type DisclaimerProps = {
	checked: boolean;
	setChecked: Dispatch<SetStateAction<boolean>>;
};

export const Disclaimer: React.ComponentType<DisclaimerProps> = (
	{
		//checked,
		//setChecked,
	},
) => {
	return (
		<StyledWrapper>
			<FlexRow align={'center'}>
				<Text
					display={'inline'}
					style={{ marginBottom: 20, marginTop: 10 }}
					variant={'subtitle2'}
				>
					By connecting your wallet, you agree to our{' '}
					<Typography
						display={'inline'}
						style={{ color: '#7680ff' }}
						onClick={() =>
							window.open('/terms-and-conditions', '_blank')
						}
					>
						Terms of Use
					</Typography>{' '}
					and{' '}
					<Typography
						display={'inline'}
						style={{ color: '#7680ff' }}
						onClick={() => window.open('/privacy', '_blank')}
					>
						Privacy Policy.
					</Typography>{' '}
				</Text>
			</FlexRow>
			{/*{show && (
				<>
					<DisclaimerWrapper>
						{DisclaimerData.map((d) => (
							<FlexCol key={d}>
								<Typography
									variant={'subtitle2'}
									style={{
										fontFamily: 'DM Mono, sans-serif',
									}}
								>
									* {d}
								</Typography>
								<Gutter spacing={2} />
							</FlexCol>
						))}
					</DisclaimerWrapper>
					<Gutter />
				</>
			)}*/}
			{/*<StyledButton
				variant={'contained'}
				onClick={() => {
					window.open('https://discord.gg/vcred');
				}}
			>
				Join Discord
			</StyledButton>*/}
		</StyledWrapper>
	);
};
