import { PayloadAction } from '@reduxjs/toolkit';
import {
	poolDepositsActions,
	RequestDepositPayload,
} from 'store/pool-deposits';
import { fork, put, take } from 'redux-saga/effects';
import { sMMPools } from 'data';
import { getErc20Contract } from 'utils/contractHelpers';
import { ethers } from 'ethers';
import {
	WalletBalance,
	walletBalancesActions,
} from 'store/wallet-balances/index';
import { depositActions } from 'store/deposit';
import { withdrawalActions } from 'store/withdrawal';

const getAssetWalletBalances = async (
	params: RequestDepositPayload,
): Promise<WalletBalance[]> => {
	const activePool =
		sMMPools.find((pool) => pool.id === params.activePoolId) || sMMPools[0];
	const poolTokens = activePool.tokens;

	return Promise.all(
		poolTokens.map(
			(token) =>
				// eslint-disable-next-line no-async-promise-executor
				new Promise<WalletBalance>(async (resolve, reject) => {
					const abis = token.poolAbis;
					const contractAddr = token.poolContractAddr.Base;
					const library = params.library;

					if (!abis || !contractAddr || !library) {
						return reject(false);
					}

					let balance = ethers.BigNumber.from('0');
					if (token.isNativeToken) {
						balance = await library.getBalance(params.account);
					} else {
						if (token.assetContractAddr.Base) {
							const assetContract = getErc20Contract(
								token.assetContractAddr.Base,
								library.getSigner(),
							);
							balance = await assetContract.balanceOf(
								params.account,
							);
						}
					}

					resolve({
						token: token.symbol,
						balance,
					});
				}),
		),
	);
};

function* fetchTokenBalances(params: RequestDepositPayload) {
	try {
		// fire balances fetched success event
		const balances: WalletBalance[] = yield getAssetWalletBalances(params);
		yield put(walletBalancesActions.requestWalletBalancesSuccess(balances));
	} catch (error) {
		yield put(walletBalancesActions.requestWalletBalancesFailed());
	}
}

export default function* saga() {
	while (true) {
		const action: PayloadAction<RequestDepositPayload> = yield take([
			poolDepositsActions.requestDeposits.type,
			depositActions.depositSuccess.type,
			withdrawalActions.withdrawalSuccess.type,
		]);

		yield fork(fetchTokenBalances, action.payload);
	}
}
