import { ChainNames } from 'config';
import type { PartialRecord } from 'types';
import tokenPool from 'abis/smm/tokenPool.json';
import Partner4 from 'assets/images/partners/partner4.svg';
import Partner4White from 'assets/images/partners/Partner4White.svg';
import Partner2 from 'assets/images/partners/partner2.svg';
import Partner2White from 'assets/images/partners/Partner2White.svg';
import Atlas from 'assets/images/poolSelection/atlas-brand.png';
import AtlasWhite from 'assets/images/poolSelection/Atlas-white.png';
import Vcred from 'assets/images/poolSelection/vcred-brand.png';

export type PoolAsset = {
	symbol: string;
	name: string;
	decimals: number;
	poolAbis: any;
	poolContractAddr: PartialRecord<ChainNames, string>;
	assetContractAddr: PartialRecord<ChainNames, string>;
	isNativeToken: boolean;
	icon: string;
	depositLimit: number;
};

type Info = {
	value: number;
	info: string;
};

type Vaults = {
	id: string | number;
	icon: string;
	alt: string;
	whiteIcon?: string;
};

type CurrentState = {
	title: string;
	value: string;
};

export type SMMPool = {
	id: string;
	name: string;
	disabled: boolean;
	illustration: { icon: string; whiteIcon: string };
	description: string;
	riskDesc: string;
	volatilityDesc: string;
	rewardDesc: string;
	backgroundColor: string;
	vaults: Vaults[];
	tags: {
		title: string;
		value: string;
	}[];
	route: { title: string; url: string };
	exchanges: { name: string; icon: string }[];
	targetAssetCategory: {
		name: string;
		chainType: string;
		coinIcon: string;
	};
	tokens: PoolAsset[];
	currentState: CurrentState[];
};

const sMMPools: SMMPool[] = [
	{
		id: 'atlas',
		name: 'Atlas',
		illustration: { icon: Atlas, whiteIcon: AtlasWhite },
		disabled: false,
		currentState: [
			{ title: 'TOTAL VOLUME', value: '$115M' },
			{ title: 'TVL', value: '100k' },
			{ title: 'EST YIELD', value: '64.76%' },
		],
		backgroundColor:
			'linear-gradient(146deg, #FFF 54.04%, #F0FAFF 79.97%), linear-gradient(180deg, #F5C089 0%, #F4B372 100%)',
		description:
			'Maximise volume, points → delta neutral trade with high capital efficiency',
		riskDesc: 'Medium',
		volatilityDesc: 'Medium',
		rewardDesc: 'Medium',
		vaults: [{ id: 1, icon: Partner4, alt: '', whiteIcon: Partner4White }],
		tags: [
			{ title: 'Estimated APY', value: '20%-40%' },
			{ title: 'Total Volume', value: '$115M' },
		],
		route: { title: 'Launch vault', url: '' },
		exchanges: [
			{
				name: 'binance',
				icon: '/crypto/binance.svg',
			},
		],
		targetAssetCategory: {
			name: 'Avalanche',
			chainType: 'L1',
			coinIcon: '/crypto/avax.svg',
		},
		tokens: [
			{
				symbol: 'USDC',
				name: 'USDC',
				decimals: 6,
				poolAbis: tokenPool,
				poolContractAddr: {
					Base: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
				},
				assetContractAddr: {
					Base: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
				},
				isNativeToken: false,
				icon: '/crypto/usdc.svg',
				depositLimit: 25,
			},
		],
	},
	{
		id: 'vcred',
		name: 'Vcred',
		illustration: { icon: Vcred, whiteIcon: Vcred },
		disabled: true,
		currentState: [
			{ title: 'TOTAL VOLUME', value: '$0,000,000' },
			{ title: 'TVL', value: '00.00%' },
			{ title: 'EST YIELD', value: '00.00%' },
		],
		backgroundColor:
			'linear-gradient(146deg, #FFF 54.04%, #F0FAFF 79.97%), linear-gradient(180deg, #F5C089 0%, #F4B372 100%)',

		description: 'Stake VCRED token to get access to your perp portfolio',
		riskDesc: 'Medium',
		volatilityDesc: 'Medium',
		rewardDesc: 'Medium',
		vaults: [
			{ id: 0, icon: Partner2, alt: 'vcred', whiteIcon: Partner2White },
		],
		route: { title: 'Launch vault', url: '' },
		tags: [
			{ title: 'Estimated APY', value: '64.76%' },
			{ title: 'Total TVL', value: '' },
		],
		exchanges: [
			{
				name: 'dydx',
				icon: '/crypto/dydx.svg',
			},
			{
				name: 'binance',
				icon: '/crypto/binance.svg',
			},
		],
		targetAssetCategory: {
			name: 'Avalanche',
			chainType: 'L1',
			coinIcon: '/crypto/avax.svg',
		},
		tokens: [
			{
				symbol: 'USDC',
				name: 'USDC',
				decimals: 6,
				poolAbis: tokenPool,
				poolContractAddr: {
					Base: '0xb111975dD31a9d99C62b049DE170475e9F0D72e8',
				},
				assetContractAddr: {
					Base: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
				},
				isNativeToken: false,
				icon: '/crypto/usdc.svg',
				depositLimit: 25,
			},
		],
	},
];

export { sMMPools };
