import React, { useMemo } from 'react';
import {
	Bar,
	BarChart,
	CartesianGrid,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import { SHARP_RATIO } from 'data/sMMCharts/sharpRatio';
import styled from '@emotion/styled';

const Card = styled.div`
	position: relative;
	border-radius: 15px;
	padding: 30px;

	@media screen and (max-width: 600px) {
		padding: 20px 0 0 0;
		margin-right: 20px;
		margin-left: -20px;
	}
`;

const CATEGORY_TICKS = [-2, 'X', -1, 'X', 0, 'X', 1, 'X', 2, 'X'];

export const SharpRatioBarCharts: React.ComponentType = () => {
	const chart = useMemo(() => {
		return (
			<BarChart data={SHARP_RATIO} barCategoryGap={0}>
				<CartesianGrid strokeDasharray="2 2" stroke="#8392BC" />
				<XAxis
					domain={[-2.5, 2.5]}
					type="number"
					ticks={CATEGORY_TICKS}
					dataKey="boundry"
				/>
				<YAxis dataKey="Occurences" />
				<Tooltip
					labelFormatter={(value: string) => [`${value} pp`, 'po']}
					formatter={(value: string) => [`${value}`, 'Occurences']}
				/>
				<Bar
					dataKey="Occurences"
					fill="#2F5FD5"
					stroke="#2F5FD5"
					strokeWidth={1}
				/>
			</BarChart>
		);
	}, []);

	return (
		<div>
			<Card>
				<ResponsiveContainer width="100%" height={500}>
					{chart}
				</ResponsiveContainer>
			</Card>
		</div>
	);
};
