import axios from 'axios';
import { BLOCKING_LOCATION } from 'data/geoLocation';

export const fetchIpInformation = async () => {
	const res = await axios.get(`https://ipapi.co/json/`);

	const isBlockedArea =
		BLOCKING_LOCATION.indexOf(res.data.country_code) !== -1;

	return isBlockedArea;
};
