import React, { useCallback, useMemo, useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import {
	DepositPanel,
	WithdrawPanel,
} from 'pages/dashboard/components/DepositWithdrawPanel/panels';
import { useAppSelector } from 'hooks';

const StyledContainer = styled(Box)`
	position: relative;
	// border: 1px solid #235ee1;
	padding: 20px 16px;
	border-radius: 12px;
	width: 100%;
	min-width: 340px;
	// background-color: #00001f;
`;

const TabGroup = styled(Box)`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 8px;
	padding: 2px;
	border-radius: 4px;
	border: 1px solid #667;
	background: #33334a;
`;

const TabButton = styled(Box)<{ selected?: boolean }>`
	flex: 1;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(props) =>
		props.selected ? '#00001f' : 'transparent'};
	border-radius: 4px;
`;

const TabText = styled(Typography)`
	font-size: 14px;
	font-weight: 500;
	margin: 12px 8px;
	line-height: 120%;
`;

enum PanelTypes {
	Deposit = 'deposit',
	Withdraw = 'withdraw',
}

const DepositWithdrawPanel: React.ComponentType = () => {
	const depositing = useAppSelector((state) => state.deposits.depositing);
	const withdrawing = useAppSelector(
		(state) => state.withdrawals.withdrawing,
	);

	const [selectedPanel, setSelectedPanel] = useState<PanelTypes>(
		PanelTypes.Deposit,
	);

	const activePanel = useMemo(() => {
		return selectedPanel === PanelTypes.Deposit ? (
			<DepositPanel />
		) : (
			<WithdrawPanel />
		);
	}, [selectedPanel]);

	const switchPanel = useCallback(
		(panel: PanelTypes) => {
			if (depositing || withdrawing) return;

			setSelectedPanel(panel);
		},
		[depositing, withdrawing],
	);

	return (
		<Box display="flex" alignItems="flex-start">
			<StyledContainer>
				{/*<TabGroup>
					<TabButton
						selected={selectedPanel === PanelTypes.Deposit}
						onClick={() => switchPanel(PanelTypes.Deposit)}
					>
						<TabText>Deposit</TabText>
					</TabButton>

					<TabButton
						selected={selectedPanel === PanelTypes.Withdraw}
						onClick={() => switchPanel(PanelTypes.Withdraw)}
					>
						<TabText>Withdrawal</TabText>
					</TabButton>
				</TabGroup>*/}

				{/*<Box height={16} />*/}

				{activePanel}
			</StyledContainer>
		</Box>
	);
};

export { DepositWithdrawPanel };
