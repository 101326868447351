export const BLOCKING_LOCATION = [
	'CA',
	'GB',
	'BY',
	'CU',
	'IR',
	'KP',
	'RU',
	'SY',
	'UA',
	'US',
];
