import React from 'react';
import { useAppSelector } from 'hooks';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const ContentBox = styled(Box)`
	display: flex;
	flex-direction: column;
`;

const FirstLine = styled(Box)`
	display: grid;
	grid-template-columns: 1fr 200px;
	grid-gap: 10px;

	@media (max-width: 600px) {
		grid-template-columns: 1fr;
		grid-auto-rows: min-content;
	}
`;

const ByTag = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-end;

	@media (max-width: 600px) {
		justify-content: initial;
	}
`;

const StrategyInfo: React.ComponentType = () => {
	const activePool = useAppSelector((state) => state.activePool.pool);

	if (!activePool) {
		return null;
	}

	return (
		<ContentBox>
			<FirstLine>
				<Typography variant="h4" fontWeight={500}>
					{activePool.name}
				</Typography>

				{/*<ByTag>
					<Typography sx={{ marginTop: '5px', color: '#A0BDF2' }}>
						by
					</Typography>
					<Box sx={{ width: 10 }} />
					<img height={20} src="/vcred-tag.svg" />
				</ByTag>*/}
			</FirstLine>

			<Box sx={{ height: 16 }} />

			<Typography color="#B3B3BB" variant={'h6'}>
				{activePool.description}
			</Typography>
		</ContentBox>
	);
};

export { StrategyInfo };
