import { createSlice } from '@reduxjs/toolkit';
import type { Draft, PayloadAction } from '@reduxjs/toolkit';

export type AssetPrice = {
	token: string;
	price: number;
};

export type AssetPricesState = {
	fetching: boolean;
	prices: AssetPrice[];
};

const initialState: AssetPricesState = {
	fetching: false,
	prices: [],
};

export const assetPricesSlice = createSlice({
	name: 'assetPrices',
	initialState,
	reducers: {
		fetchAssetPrices: (state: Draft<AssetPricesState>) => {
			state.fetching = true;
		},
		assetPricesReceived: (
			state: Draft<AssetPricesState>,
			action: PayloadAction<AssetPricesState['prices']>,
		) => {
			console.log(state);
			console.log(action.type);
			console.log(action.payload);
		},
	},
});
export const assetPriceActions = assetPricesSlice.actions;
const assetPriceReducer = assetPricesSlice.reducer;
export default assetPriceReducer;
