import type { Draft, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { SMMPool } from 'data';

export type RequestDepositPayload = {
	account: string;
	library: any;
	activePoolId: SMMPool['id'];
};

export type Deposits = {
	deposit: string;
	token: string;
}[];

export type PoolDepositsState = {
	deposits: Deposits;
	fetching: boolean;
};

const initialState: PoolDepositsState = {
	fetching: false,
	deposits: [
		{
			deposit: '50',
			token: 'AVAX',
		},
		{
			deposit: '',
			token: 'USDC',
		},
		{
			deposit: '',
			token: 'BTC.B',
		},
	],
};

export const poolDepositsSlice = createSlice({
	name: 'pool-deposits',
	initialState,
	reducers: {
		requestDeposits: (
			state: Draft<PoolDepositsState>,
			_: PayloadAction<RequestDepositPayload>,
		) => {
			state.fetching = true;
		},
		requestDepositsSuccess: (
			state: Draft<PoolDepositsState>,
			action: PayloadAction<Deposits>,
		) => {
			state.fetching = false;
			state.deposits = action.payload;
		},
		requestDepositsFailed: (state: Draft<PoolDepositsState>) => {
			state.fetching = false;
		},
	},
});

export const poolDepositsActions = poolDepositsSlice.actions;
const poolDepositReducer = poolDepositsSlice.reducer;
export default poolDepositReducer;
