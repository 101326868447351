import * as React from 'react';
import { useEffect, useState } from 'react';
import { useEagerConnect } from 'hooks';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { fetchIpInformation } from 'utils/geolocation';
import NotServedPage from 'pages/NotFound';
import { Dashboard, PoolSelection } from 'pages';
import { Navbar } from 'components';
import LoadingComponent from 'pages/Loading';
import TermsAndCondition from 'pages/TermsAndCondition';
import PrivacyAndPolicy from 'pages/Privacy and policy';

const DApp: React.ComponentType = () => {
	const [loading, setLoading] = useState<boolean>(true);
	const [isBlocked, setIsBlocked] = useState<boolean>(false);

	// const dispatch = useDispatch();
	useEagerConnect();

	const setup = async () => {
		try {
			setLoading(true);
			const res = await fetchIpInformation();
			setIsBlocked(res);
		} catch (error) {
			// console.error('Error fetching the IP address:', error);
		}
		setLoading(false);
	};

	useEffect(() => {
		setup();
	}, []);

	return (
		<Router>
			<div>
				{loading && <LoadingComponent />}
				{!loading && !isBlocked && (
					<>
						<Navbar />

						<Switch>
							<Route path="/main">
								<Dashboard />
							</Route>
							<Route path="/terms-and-conditions">
								<TermsAndCondition />
							</Route>
							<Route path="/privacy">
								<PrivacyAndPolicy />
							</Route>
							<Route path="/">
								<PoolSelection />
							</Route>
						</Switch>
					</>
				)}
				{isBlocked && <NotServedPage />}
			</div>
		</Router>
	);
};

export default DApp;
