import React from 'react';
import styled from '@emotion/styled';
import { FlexCol, FlexRow } from 'components/Flex';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'hooks';

const Item = styled(FlexRow)`
	padding: 18px 10px;
	width: 100%;
	min-width: 200px;
	border-radius: 7px;
	overflow: hidden;
	border: 1px solid #d6d6d6;
	align-items: center;
	background: transparent;
`;

const Grid = styled('div')<{ length: number }>`
	display: grid;
	width: 100%;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	column-gap: 10px;
	row-gap: 10px;
`;

const ItemText = styled(FlexCol)`
	flex: 1;
	padding-left: 10px;
	justify-content: center;
	align-items: flex-start;
`;

const Wrapper = styled(FlexRow)`
	width: 100%;
	align-items: center;
	//padding: 10px;
`;

export const CurrentState = () => {
	const activePool = useAppSelector((state) => state.activePool.pool);

	if (!activePool) {
		return null;
	}

	return (
		<Grid length={activePool.currentState.length}>
			{activePool.currentState.map((sts) => (
				<Wrapper key={sts.title}>
					<Item>
						<ItemText>
							<Typography variant={'caption'} fontWeight={500}>
								{sts.title}
							</Typography>
							<Box height={8} />
							<Typography variant={'h5'} fontWeight={500}>
								{sts.value}
							</Typography>
						</ItemText>
					</Item>
				</Wrapper>
			))}
		</Grid>
	);
};
