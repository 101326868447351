import { ethers } from 'ethers';
import erc20Abi from 'abis/erc20Abi.json';

export const getContract = (
	abi: any,
	address: string,
	signer: ethers.Signer | ethers.providers.Provider,
) => {
	return new ethers.Contract(address, abi, signer);
};

export const getErc20Contract = (
	address: string,
	signer: ethers.Signer | ethers.providers.Provider,
) => {
	return getContract(erc20Abi, address, signer);
};
