import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Box, outlinedInputClasses, selectClasses } from '@mui/material';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';

const Option = styled(MenuItem)(() => ({
	backgroundColor: '#1A1A33',
	color: '#fff',
	padding: '17px 20px',
}));

const StyledSelect = styled(Select)(() => ({
	border: '1px solid #666677',

	[`& .${selectClasses.select} `]: {
		minWidth: 80,
		borderRadius: 4,
		backgroundColor: 'transparent',

		fontSize: 14,
	},

	[`& .${selectClasses.outlined}`]: {
		color: 'white',
		fontWeight: 'bold',
	},

	[`& .${selectClasses.iconOutlined}`]: {
		color: 'white',
	},

	[`&:hover .${outlinedInputClasses.input}`]: {
		color: '#fff',
	},
}));

type ItemType = { label: string; value: string };

type Props = {
	dataSource: ItemType[];
	defaultValue?: string;
};

export const DropDown: React.ComponentType<Props> = ({
	dataSource,
	defaultValue,
}) => {
	const [selectedOption, setSelectedOption] = useState<string>(
		defaultValue || '',
	);

	const handleChange = (event: any) => {
		setSelectedOption(event.target.value as string);
	};

	return (
		<Box sx={{ minWidth: 140 }}>
			<StyledSelect
				size="small"
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				placeholder="Sort by"
				value={selectedOption}
				onChange={handleChange}
				MenuProps={{
					MenuListProps: {
						style: { padding: 0 },
					},
					PaperProps: {
						style: {
							backgroundColor: '#505050',
							border: '1px solid #666677',
						},
					},
				}}
			>
				{dataSource.map((item) => (
					<Option
						key={item.label}
						value={item.value}
						onClick={() => {
							setSelectedOption(item.value);
						}}
					>
						<Typography variant={'button'} fontStyle={'normal'}>
							{item.label}
						</Typography>
					</Option>
				))}
			</StyledSelect>
		</Box>
	);
};
