import React, { useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { YourDeposit } from 'pages/dashboard/components/PersonalStats/YourDeposit';
import { YourPnL } from 'pages/dashboard/components/PersonalStats/YourPnL';
import Typography from '@mui/material/Typography';
import { Gutter } from 'components/Gutter';
import { DropDown } from 'components';
import { DropdownData } from 'data/dropdown';
import { OtherPartnerPoints } from 'pages/dashboard/components/PersonalStats/OtherPartnerPoints';

const StatsBox = styled(Box)`
	box-sizing: border-box;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;

	@media (max-width: 1200px) {
		grid-template-columns: 1fr 1fr;
		grid-auto-rows: min-content;

		& > div:nth-child(3) {
			grid-column: span 2;
		}
	}
`;

const TabGroup = styled(Box)`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 4px;
	padding: 1px;
	background-color: #33334a;
	border-radius: 4px;
	border: 1px solid #666677;
`;

const TabButton = styled(Box)<{ selected?: boolean }>`
	flex: 1;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(props) =>
		props.selected ? '#00001f' : 'transparent'};
	border-radius: 4px;
	padding: 0;
`;

const TabText = styled(Typography, {
	shouldForwardProp: (props) => props !== 'isSelected',
})<{ isSelected?: boolean }>`
	margin: 8px 8px;
	padding: 0;
	color: ${(props) => (props.isSelected ? '#ffffff' : '#CCCCD1')};
`;

type AmountType = 'Token' | '$USD';

const PersonalStats: React.ComponentType = () => {
	const [amountType, setAmountType] = useState<AmountType>('Token');

	return (
		<Box>
			<Box display="flex" justifyContent="flex-start">
				<DropDown dataSource={DropdownData} defaultValue={'highest'} />
				<Gutter gap={0.5} />
				<TabGroup>
					<TabButton
						selected={amountType === 'Token'}
						onClick={() => setAmountType('Token')}
					>
						<TabText
							fontStyle={'normal'}
							variant={'button'}
							isSelected={amountType === 'Token'}
						>
							Token
						</TabText>
					</TabButton>

					<TabButton
						selected={amountType === '$USD'}
						onClick={() => setAmountType('$USD')}
					>
						<TabText
							fontStyle={'normal'}
							variant={'button'}
							isSelected={amountType === '$USD'}
						>
							$USD
						</TabText>
					</TabButton>
				</TabGroup>
			</Box>

			<Gutter />
			<StatsBox>
				<YourDeposit />
				<YourPnL />
				<OtherPartnerPoints />
			</StatsBox>
			{/*<IVcredRewards />*/}
		</Box>
	);
};

export { PersonalStats };
