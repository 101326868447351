import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { PoolAsset } from 'data';
import { RequestDepositPayload } from 'store/pool-deposits';

export type InitiateWithdrawalParams = {
	account: string;
	library: any;
	amount: string;
	asset: PoolAsset;
};

export type WithdrawalState = {
	withdrawing: boolean;
};

const initialState: WithdrawalState = {
	withdrawing: false,
};

export const withdrawalSlice = createSlice({
	name: 'withdrawal',
	initialState,
	reducers: {
		withdraw: (
			state: Draft<WithdrawalState>,
			action: PayloadAction<InitiateWithdrawalParams>,
		) => {
			state.withdrawing = true;
		},
		withdrawalSuccess: (
			state: Draft<WithdrawalState>,
			// TODO: fix type
			_: PayloadAction<RequestDepositPayload>,
		) => {
			state.withdrawing = false;
		},
		withdrawalFailed: (state: Draft<WithdrawalState>) => {
			state.withdrawing = false;
		},
	},
});

export const withdrawalActions = withdrawalSlice.actions;
const withdrawalReducer = withdrawalSlice.reducer;
export default withdrawalReducer;
