import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const ContentBox = styled(Box)`
	border: 1px solid #002581;
	background-color: #00001f;
	padding: 12px 16px;
`;

const DottedGutter = styled(Box)`
	margin: 12px 0;
	border-top: 1px dashed #6a7cb0;
	opacity: 0.7;
`;

const Temp = styled('div')`
	min-height: 26px;
`;

const Wrapper = styled(Box)`
	padding: 5px;
	background-color: #1e2841;
	border-radius: 4px;
`;

const OtherPartnerPoints: React.ComponentType = () => {
	return (
		<ContentBox>
			<Box display="flex" alignItems="center">
				<Typography color="#CCCCD1" fontSize="14px" fontWeight="700">
					Our partner points
				</Typography>
				<Box sx={{ width: 8 }} />
				<Wrapper>
					<Typography
						color="#aaa"
						fontSize="12px"
						fontWeight="600"
						variant={'subtitle2'}
					>
						Coming soon...
					</Typography>
				</Wrapper>
				{/*<Box sx={{ width: 8 }} />
				<Typography color="#80808E" fontSize="12px" fontWeight="700">
					24 h
				</Typography>*/}
			</Box>

			<Box height={22} />

			<Box display="flex" justifyContent="space-between">
				<Box display="flex" alignItems="baseline">
					<Temp />
					{/*<AmountLabel>0</AmountLabel>
					<Box width={4} />
					<AssetLabel>AVAX</AssetLabel>*/}
				</Box>

				{/*<Box
					border="2px solid #2BE8AA"
					display="flex"
					alignItems="center"
					padding="2px"
				>
					<Typography color="#2BE8AA" fontWeight="bold">
						0.00%
					</Typography>
				</Box>*/}
			</Box>

			<DottedGutter />

			<Box display="flex" justifyContent="space-between">
				<Box display="flex" alignItems="baseline">
					<Temp />
					{/*<AmountLabel>0</AmountLabel>
					<Box width={4} />
					<AssetLabel>USDC</AssetLabel>*/}
				</Box>

				{/*<Box
					border="2px solid #2BE8AA"
					display="flex"
					alignItems="center"
					padding="2px"
				>
					<Typography color="#2BE8AA" fontWeight="bold">
						0.00%
					</Typography>
				</Box>*/}
			</Box>

			<DottedGutter />

			<Box display="flex" justifyContent="space-between">
				<Box display="flex" alignItems="baseline">
					<Temp />
					{/*<AmountLabel>0</AmountLabel>
					<Box width={4} />
					<AssetLabel>BTC.B</AssetLabel>*/}
				</Box>

				{/*<Box
					border="2px solid #2BE8AA"
					display="flex"
					alignItems="center"
					padding="2px"
				>
					<Typography color="#2BE8AA" fontWeight="bold">
						0.00%
					</Typography>
				</Box>*/}
			</Box>
		</ContentBox>
	);
};

export { OtherPartnerPoints };
