import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const LoadingComponent = ({ message = 'Please wait...' }) => {
	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			height="100vh"
		>
			<CircularProgress />
			<Typography variant="h6" style={{ marginTop: '16px' }}>
				{message}
			</Typography>
		</Box>
	);
};

export default LoadingComponent;
