export const xAxisTickStyle = (isDark: boolean) => {
	return {
		fontSize: 12,
		fill: isDark ? '#FFF' : 'initial',
	};
};

export const xAxisLabelStyle = (isDark: boolean) => {
	return {
		fill: isDark ? '#fff' : '#ffff',
		fontWeight: 600,
	};
};

export const yAxisLabelStyle = (isDark: boolean) => {
	return {
		fill: isDark ? '#fff' : '#1D1D28',
		fontWeight: 600,
		marginRight: 60,
	};
};
